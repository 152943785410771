import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.sms-log', route: { name: 'sms' } },
      ],
      listRoute: () => ({ name: 'sms' }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['id']),
      pageTitleBase: () => 'module.sms',
      blocks: [
        {
          title: 'form_block.base',
          readonly: true,
          data: {
            phone_number: {
              label: 'sms.data.phone_number',
              copyable: true,
              type: 'text',
            },
            message: {
              label: 'sms.data.message',
              copyable: true,
              type: 'textarea',
            },
            status: {
              label: 'sms.data.status',
              type: 'readonly-content',
              containerComponent: 'text',
              prefix: 'sms.data.status',
              i18n: true,
            },
            send_at: {
              label: 'sms.data.send_at',
              type: 'text',
            },
            response: {
              label: 'sms.data.response',
              copyable: true,
              type: 'readonly-content',
              containerComponent: 'textarea',
              json: true,
            },
            error: {
              label: 'sms.data.error',
              copyable: true,
              type: 'readonly-content',
              containerComponent: 'textarea',
              json: true,
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
